import React from 'react';
import config from '../config';
import { desaturate } from 'polished';
import { imageParser, getDate, getMonth, getDateTimeTimezone, diffDate } from '../utils/utils';
import { ListItem, ListItemAvatar, ListItemText, Avatar, Typography, LinearProgress } from '@material-ui/core';
import { Icon } from '../components/Images/Images';

export default function ListItemStatistics(props) {
    const { type, image, bgColor, title, progress, completed, gutters, action, dateStart, dateEnd } = props;
    const stc = require('string-to-color');
    let singleClass = diffDate(dateStart, dateEnd) ? true : false;

    const ListImage = () => {
        switch (type) {
            case "scorm":
                return (
                    <ListItemAvatar>
                        <div className="__rectangle" style={{ backgroundColor: bgColor, backgroundImage: image ? `url(${imageParser(image)})` : "" }}></div>
                    </ListItemAvatar>
                )
            case "classroom":
                return (
                    <ListItemAvatar>
                        <div className={`__rectangle large${!image ? ' no-image' : ""}`} style={{
                            backgroundColor: desaturate(0.25, stc(title)),
                            backgroundImage: image ? `url(${imageParser(image)})` : ""
                        }}>
                            <div className="calendar-content">
                                <div className="calendar-date">
                                    <Typography variant="body2" className="day" component="p" noWrap>
                                        {getDate(dateStart)}{singleClass ? "+" : null}
                                    </Typography>
                                    <Typography variant="caption" className="month" component="p" noWrap>
                                        {getMonth(dateEnd)}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </ListItemAvatar>
                )
            default:
                return (
                    image ?
                        <ListItemAvatar>
                            <Avatar className="__rectangle" aria-label={title} style={{ backgroundColor: bgColor }}>
                                <img src={imageParser(image)} alt={title} />
                            </Avatar>
                        </ListItemAvatar>
                        : null
                );
        }
    }

    const ListContent = () => {
        switch (type) {
            case "trail":
                return (
                    <ListItemText
                        disableTypography
                        primary={
                            <div className="list-item-statistics-content">
                                <Typography variant="body1" component="h4" className="mb-2 mr-3">
                                    {title}
                                </Typography>
                            </div>
                        }
                        secondary={
                            <div className="list-item-statistics-stars">
                                {
                                    config.learn.trail.type === "stars" ?
                                        <Icon ic="star_filled" className="icon-xxs mb-1" />
                                        : null
                                }
                                <Typography variant="body2" component="p">
                                    {progress}
                                </Typography>
                            </div>
                        }
                    />
                )
            case "classroom":
                return (
                    <ListItemText
                        disableTypography
                        primary={
                            <div className="list-item-statistics-content">
                                <Typography variant="body1" component="h4" className="mb-2 mr-3">
                                    {title}
                                </Typography>
                            </div>
                        }
                        secondary={
                            <div className="d-flex align-items-center w-100">
                                <Typography variant="body2" component="p">
                                    {getDateTimeTimezone(dateStart)} - {getDateTimeTimezone(dateEnd)}
                                </Typography>
                            </div>
                        }
                    />
                )
            default:
                return (
                    <ListItemText
                        disableTypography
                        primary={
                            <div className="list-item-statistics-content">
                                <Typography variant="body1" component="h4" className="mb-2 mr-3">
                                    {title}
                                </Typography>
                                <Typography variant="h6" component="p">
                                    {progress}%
                                </Typography>
                            </div>
                        }
                        secondary={
                            <LinearProgress variant="determinate" value={progress} />
                        }
                    />
                );
        }
    }

    return (
        <ListItem button={action ? true : false} className="list-item-statistics" disableGutters={!gutters} onClick={action ? action : false}>
            <div className="list-item-statistics-image">
                <ListImage />
            </div>

            <ListContent />

            {
                completed ?
                    <Icon ic="checked" color="success" className="icon-sm" />
                    : null
            }
        </ListItem>
    );
}