import React from 'react';
import intl from 'react-intl-universal';
import { getDateTimeTimezone, examTimer, dateMask } from '../../../../utils/utils';
import { ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { Icon } from '../../../../components/Images/Images';
import ImgBrief from '../../../../dist/img/svgr/ImgBrief';
import ImgBriefInProgress from '../../../../dist/img/svgr/ImgBriefInProgress';
import ImgBriefFinished from '../../../../dist/img/svgr/ImgBriefFinished';
import ImgBriefNotFinished from '../../../../dist/img/svgr/ImgBriefNotFinished';
import ImgBriefWaitingResult from '../../../../dist/img/svgr/ImgBriefWaitingResult';

export default function ExamListItem(props) {
    const { action, item, status } = props;

    return (
        <ListItem button onClick={action}>
            <ListItemAvatar>
                {
                    status === "to_do" ?
                        item.in_progress === "Y" ?
                            <ImgBriefInProgress className="img-lg" />
                            :
                            <ImgBrief className="img-lg" />
                        :
                        item.done === "Y" ?
                            item.exam_feedback_available === "Y" ?
                                <ImgBriefFinished className="img-lg" />
                                :
                                <ImgBriefWaitingResult className="img-lg" />
                            :
                            <ImgBriefNotFinished className="img-lg" />
                }
            </ListItemAvatar>
            <ListItemText
                disableTypography
                primary={
                    <Typography variant="body1" component="p">
                        {item.name}
                    </Typography>
                }
                secondary={
                    status === "to_do" ?
                        <div className="d-flex flex-row align-items-center">
                            <Icon ic="date_start" className="icon-xxs mr-1" />
                            <Typography variant="body2" component="p">
                                {getDateTimeTimezone(item.exam_start_date)}
                            </Typography>
                        </div>
                        : status === "done" ?
                            <div className="d-flex flex-row align-items-center">
                                {
                                    item.session && item.session.user_finish_date ?
                                        <>
                                            <Icon ic="event_accepted" className="icon-xxs mr-1" />
                                            <Typography variant="body2" component="p">
                                                {getDateTimeTimezone(item.session.user_finish_date)}
                                            </Typography>
                                        </>
                                        :
                                        <Typography variant="body2" component="p">
                                            {intl.get("EXAM_UNREALIZED")}
                                        </Typography>
                                }
                            </div>
                            :
                            <Typography variant="body2" component="p">
                                {`${intl.get("EXAM_RESULT_MESSAGE")} ${dateMask(item.exam_feedback_date)}`}
                            </Typography>
                }
            />
            {
                status === "to_do" ?
                    <div className="d-flex flex-row flex-nowrap align-items-center ml-3">
                        <Icon ic="hourglass" className="icon-xxs mr-1" />
                        <Typography variant="caption" component="span" noWrap>
                            {examTimer(item.exam_start_date, item.exam_end_date)}
                        </Typography>
                    </div>
                    : null
            }
        </ListItem>
    )
}