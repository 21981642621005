import cryptoJS from 'crypto-js';
import intl from 'react-intl-universal'
import * as moment from 'moment';
import * as timezone from 'moment-timezone';
import 'moment/locale/pt-br';
import 'moment/locale/pt';
import 'moment/locale/es';
import { currentLocale } from '../router/Router'
import config from '../config';
import { Icon } from "../components/Images/Images";
import React from "react";

var HtmlToReactParser = require('html-to-react').Parser;

export function download_csv(csv, data) {
    var universalBOM = "\uFEFF";
    try {
        data.forEach(function (row) {
            csv += row.join(';');
            csv += "\n";
        });
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM + csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'history.csv';
        hiddenElement.click();
    } catch (err) {
        console.log('O seguinte erro ocorreu: ', err)
    }

}
export function feedDateTime(date) {
    moment.locale(currentLocale)
    let now = moment()
    let min = now.diff(date, 'minutes')
    let hours = now.diff(date, 'hours')
    let days = now.diff(date, 'day')

    if (min < 60 && hours < 1) {
        if (min < 1) {
            return intl.get('LABEL_NOW')
        } else {
            return (min + ' min')
        }
    } else if (min > 60 && hours < 24) {
        return (hours + ' h')
    } else if (days === 1) {
        return intl.get('LABEL_YESTERDAY')
    } else {
        return moment(date).format('ll')
    }
}

export function getDateFormat(date) {
    moment.locale(currentLocale)
    return moment(date).format('YYYY-MM-DD');
}

export function getFullDate(date) {
    moment.locale(currentLocale)
    return moment(date).format('DD, MMM. YYYY')
}

export function getMonth(date) {
    moment.locale(currentLocale)
    return moment(date).format('MMM')
}

export function getYear(date) {
    moment.locale(currentLocale)
    return moment(date).format('YYYY')
}

export function getFomattedMonth(date) {
    moment.locale(currentLocale)
    return moment(date).format('DD/MM/YYYY')
}

export function getDate(date) {
    moment.locale(currentLocale)
    return moment(date).format('DD')
}

export function getHours(date) {
    moment.locale(currentLocale)
    return moment(date).format('HH:mm')
}

export function getDateTime(date) {
    moment.locale(currentLocale)
    return moment(date).format('DD MMM YYYY à\\s HH:mm')
}

export function getDateTimeTimezone(date) {
    moment.locale(currentLocale)

    // Define explicitamente o timezone da data do servidor
    const brasiliaTime = timezone.tz(date, "America/Sao_Paulo");

    // Obtém o fuso horário do usuário
    const userTimezone = timezone.tz.guess();

    // Converte a data para o fuso horário do usuário
    const userTime = brasiliaTime.tz(userTimezone);

    return moment(userTime).format('DD MMM YYYY à\\s HH:mm')
}

export function diffDate(dateOne, dateTwo) {
    moment.locale(currentLocale)
    return moment(dateTwo).diff(dateOne, 'days') >= 1
}

export function dateGreaterThen(date) {
    moment.locale(currentLocale)
    return moment(date).diff(moment().format(), 'days') >= 0
}

export function diffMoment(date) {
    moment.locale(currentLocale)
    return moment(date).diff(moment().format(), 'seconds') >= 0
}

export function getTimer(time) {
    var splits = time.split(":");
    return (splits[0] === "00" ? "" : splits[0] + "h ") + (splits[1] === "00" ? "" : splits[1] + "m ")
}

export function getSecondToHours(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
}

export function maskCPF(cpf) {
    return cpf
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export function maskCellphone(num) {
    num = num.replace(/\D/g, "")
    num = num.replace(/^(\d)/, "+$1")
    num = num.replace(/(.{3})(\d)/, "$1($2")
    num = num.replace(/(.{6})(\d)/, "$1)$2")
    if (num.length === 12) {
        num = num.replace(/(.{1})$/, "-$1")
    } else if (num.length === 13) {
        num = num.replace(/( .{2})$/, "-$1")
    } else if (num.length === 14) {
        num = num.replace(/(.{3})$/, "-$1")
    } else if (num.length === 15) {
        num = num.replace(/(.{4})$/, "-$1")
    } else if (num.length > 15) {
        num = num.replace(/(.{4})$/, "-$1")
    }
    return num;
}

export function passwordValidation(password) {
    return /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()-_=+}{]{8,})/.test(password);
}

export function emailValidation(email) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}
export function examDateTime(date) {
    moment.locale(currentLocale)
    return moment(date).format('ll à\\s HH:mm')
}
export function examTimer(date, date_end) {
    moment.locale(currentLocale)
    let sec = moment(date_end).diff(date, 'seconds')
    let min = moment(date_end).diff(date, 'minutes')
    let hours = moment(date_end).diff(date, 'hours')
    let days = moment(date_end).diff(date, 'day')

    if (min < 60 && hours < 1) {
        if (min < 1) {
            return (sec + ' s')
        } else {
            return (min + ' min')
        }
    } else if (min > 60 && hours < 24) {
        return (hours + ' h')
    } else {
        return (days + ' d')
    }
}
export function classroomDateEnd(date) {
    moment.locale(currentLocale)
    return moment(date).format('DD MMM YYYY à\\s HH:mm')
}

export function dateMask(date) {
    return moment(date).format('DD/MM/YYYY')
}

export function linkVideoParser(url) {
    let regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|m\.youtube\.com\/|youtube\.com\/)(?:embed\/|v\/|watch\?v=|watch\?.+&v=|)((\w|-){11})(?:\S+)?$/
    return ((url.match(regExp))
        ? 'https://youtube.com/embed/' + RegExp.$1 // YOUTUBE
        : /^(?:https?:\/\/)?(?:web\.)?(?:microsoft\/|microsoftstream\.com\/(?:embed\/|video))/gi.test(url)
            ? `https://web.microsoftstream.com/embed/video/${url.split('/video/')[1]}?autoplay=false&amp;showinfo=true` // MICROSOFT
            : /^(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/gi.test(url) //Vimeo
                ? `https://player.vimeo.com/video/${url.match(/(\d+)/i)[0]}`
                : url
    );
}

export function generateToken(data) {
    var hash = cryptoJS.HmacSHA256(data, process.env.REACT_APP_PVT_ID)
    var hashString = hash.toString()
    return hashString
}

export function imageParser(image) {

    let imgFileData = null;
    if (image && image.blob) {
        imgFileData = {
            'storage_type': image.blob.storage_type,
            'blob_container': image.blob.blob_container,
            'file_path': !image.blob.file_path || image.blob.file_path === '' ? image.blob.file_name : image.blob.file_path + image.blob.file_name,
            'mime_type': image.blob.mime_type,
            'file_name': image.blob.file_name
        }
    } else {
        if (image.source) {
            return image.source;
        } else {
            return image;
        }
    }
    return process.env.REACT_APP_API_BASEPATH + '/v1/app/get/file' + toQueryString(imgFileData);
}

export function generateNonce(length) {
    var text = ''
    var possible = 'abcdefghijklmnopqrstuvwxyz'
    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
}

export function timestamp() {
    return Math.floor(new Date().getTime() / 1000)
}

export function toQueryString(obj) {
    let res = [];
    for (let i in obj) {
        res.push(i + '=' + obj[i]);
    }

    return '?' + res.join('&');
}

export function gotoTop() {
    window.scrollTo(0, 0);
}

export function goToTopAnimation() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}

export function numberMask(number) {
    var formatted = 0;
    if (number) {
        number = number.toString();
        var invertedArray = number.split("").reverse().join("");

        var splitedArray = [];
        var i = 0;
        for (i = 0; i < number.length; i = i + 3)
            splitedArray.push(invertedArray.substring(i, i + 3).split("").reverse().join(""))
        for (i = splitedArray.length - 1; i >= 0; i--) {
            formatted += splitedArray[i] + ".";
        }
        formatted = formatted.substring(1, formatted.length);
        formatted = formatted.substring(0, formatted.length - 1);
    }
    return formatted;
}

export function numberSocialMask(number) {
    var formatted = 0;
    if (number) {
        number = number.toString();
        var invertedArray = number.split("").reverse().join("");
        var splitedArray = [];
        var i = 0;
        for (i = 0; i < number.length; i = i + 3)
            splitedArray.push(invertedArray.substring(i, i + 3).split("").reverse().join(""))

        formatted = splitedArray[splitedArray.length - 1];
        switch (splitedArray.length) {
            case 3:
                formatted += '.' + splitedArray[0].charAt(0) + 'KK'
                break;
            case 2:
                formatted += '.' + splitedArray[0].charAt(0) + 'K'
                break;
            case 1:
                break;
            default:
        }

    }
    return formatted;
}

function str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
}

export function getFormattedHours(minutes) {
    minutes = minutes * 3600;
    return str_pad_left(Math.floor(minutes / 3600), '0', 2) + ':' + str_pad_left(((minutes % 3600) / 60), '0', 2) + ':' + str_pad_left(minutes % 60, '0', 2);
}

export function getAverage(value) {
    return (value * 100);
}

export function getTypeCourse(type) {
    switch (type) {
        case "classroom":
            return intl.get("CLASSROOM_TITLE");
        case "scorm":
            return intl.get("SCORM_TITLE");
        case "course":
            return intl.get("TRAIL_TITLE");
        case "exam":
            return intl.get("EXAM_TITLE");
        case "free":
            return intl.get("FREE_TITLE");
        default:
    }
}

export function identifyIphone() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function verifyIfIsValidNumber(data) {
    if (isNaN(data))
        return '0';
    if (!data)
        return '0';
    return data;
}

// Função para retornar o idioma do aparelho do usuário com underline ao invés de hífen
export function getLanguage(locale) {
    switch (locale) {
        case "pt-BR":
            return "pt_BR"
        case "en-US":
            return "en_US"
        case "es-ES":
            return "es_ES"
        default:
            return "pt_BR"
    }
}

export function ParseHtml(question) {
    var htmlInput = question;
    var htmlToReactParser = new HtmlToReactParser();
    var reactElement = htmlToReactParser.parse(htmlInput);
    return (reactElement);
}

export function reorderArray(array, name) {
    array.sort(function (a, b) {
        return config[name].indexOf(a) - config[name].indexOf(b);
    });
    return array;
}

export function configNotAllowed(projectName) {
    var showField = true;
    projectName.forEach(project => {
        if (process.env.REACT_APP_QR_PROJECT === project) {
            showField = false;
        }
    })
    return showField;
}

export function getKey(data, item) { // DATA é o objeto, item o item que deseja encontrar na chave
    return Object.keys(data).find(key => key.includes(item));
}


export function b64toBlob(dataURI, type) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: type });
}

export function numberToLetter(index) {
    return String.fromCharCode(96 + index);
}

export function isBase64(str) {
    var regexBase64 = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return regexBase64.test(str);
}

export function typeFileFormat(file) {
    switch (file) {
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return ".docx";
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return ".xls";
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return ".ppt";
        case 'application/pdf':
            return ".pdf";
        case 'video/mp4':
            return ".mp4";
        case 'audio/mpeg':
            return ".mp3";
        default:
            return "";
    }
}

export function typeFileIcon(file) {
    switch (file) {
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return <Icon ic="file_text" className="mr-2" />;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return <Icon ic="file_spreadsheet" className="mr-2" />;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return <Icon ic="file_presentation" className="mr-2" />;
        case 'application/pdf':
            return <Icon ic="file_text" className="mr-2" />;
        case 'video/mp4':
            return <Icon ic="file_video" className="mr-2" />;
        case 'audio/mpeg':
            return <Icon ic="file_audio" className="mr-2" />;
        default:
            return "";
    }
}

export function getPeriodicTitle(type) {
    switch (type) {
        case "DAILY":
            return intl.get("PERIODIC_DAILY_TITLE");
        case "WEEKLY":
            return intl.get("PERIODIC_WEEKLY_TITLE");
        case "MONTHLY":
            return intl.get("PERIODIC_MONTHLY_TITLE");
        default:
            return;
    }
}

export function getSectionType(type) {
    switch (type) {
        // Prêmios
        case "PRE_FDISP":
        case "PRE_RESG":
        case "PRE_DISP":
        case "PRE_NOVO":
            return {
                section: "section-prizes",
                icon: "gift_filled",
                image: false
            }

        // Amigo (Avatar)
        case "RES_AMIGO":
            return {
                section: "",
                icon: "",
                image: true
            }

        // Pergunta do dia
        case "PER_DIA":
            return {
                section: "section-play-periodic",
                icon: "play_periodic_question",
                image: false
            }

        // Livre
        case "CAT_NOVA_SP":
        case "CAT_NOVA_FILHOS_SP":
        case "CAT_PERG_SP":
            return {
                section: "section-play-free",
                icon: "brain_filled",
                image: false
            }

        // Duelos
        case "CAT_NOVA_MP":
        case "CAT_PERG_MP":
            return {
                section: "section-play-duels",
                icon: "play_duels",
                image: false
            }

        // Trilha
        case "CUR_NOVO":
            return {
                section: "section-play-trail",
                icon: "play_trail",
                image: false
            }

        // Scorms
        case "SCO_NOVO":
            return {
                section: "section-play-scorm",
                icon: "play_scorm",
                image: false
            }

        // Exames
        case "EXA_NOVO":
            return {
                section: "section-play-exam",
                icon: "play_exam",
                image: false
            }

        // Treinamento Presencial
        case "TRE_NOVO":
            return {
                section: "section-play-classroom",
                icon: "play_classroom",
                image: false
            }

        // Nova Categoria (TODOS os módulos)
        case "CAT_PERG":
        case "CAT_NOVA":
            return {
                section: "section-play",
                icon: "brain_filled",
                image: false
            }

        // Ranking
        case "RAN_CHAMP_MONTH":
        case "RAN_CHAMP_WEEK":
        case "RAN_CHAMP_DAY":
        case "RAN_SUB":
        case "RAN_DESC":
        case "RAN_NOVA":
            return {
                section: "section-ranking",
                icon: "trophy_filled",
                image: false
            }

        // Conquistas
        case "MIS_NOVA":
            return {
                section: "section-achievements",
                icon: "medal",
                image: false
            }

        // Vídeos
        case "VID_EXIBIR":
            return {
                section: "section-video",
                icon: "video",
                image: false
            }

        // Feeds
        case "AVI_GERAL":
        case "LOG_BONIF":
        case "BAD_AMIGO":
        case "QUI_CONS":
        case "LNK_WEB":
            return {
                section: "section-feeds",
                icon: "feed_filled",
                image: false
            }

        // Aniversário
        case "ANI_BONUS":
            return {
                section: "section-birthday",
                icon: "birthday",
                image: false
            }

        // Usuário Premium
        case "ASS_BENEF":
            return {
                section: "section-premium",
                icon: "crown_filled",
                image: false
            }

        default:
            return;
    }
}

export function getWordByInitials(value) {
    switch (value) {
        case "Y":
            return intl.get("LABEL_YES");
        case "N":
            return intl.get("LABEL_NO");
        default:
            return "-";
    }
}
export function getIconCompleted(value) {
    switch (value) {
        case "Y":
            return <Icon ic="checked" color="success" />;
        case "N":
            return <Icon ic="hourglass" color="info" />;
        default:
            return <Icon ic="negative" color="lighter" />;
    }
}
export function getStatusCourseByType(value, type) {
    if (type === 'classroom') {
        switch (value) {
            case "Y":
                return intl.get("CLASSROOM_PRESENT_LABEL");
            case "N":
                return intl.get("CLASSROOM_ABSENT_LABEL");
            default:
                return intl.get("LABEL_IN_PROGRESS");
        }
    } else {
        switch (value) {
            case "Y":
                return intl.get("LABEL_COMPLETED");
            case "N":
                return intl.get("LABEL_IN_PROGRESS");
            default:
                return intl.get("LABEL_NOT_STARTED");
        }
    }
}
export function getIconCourseByType(value, type) {
    if (type === 'classroom') {
        switch (value) {
            case "Y":
                return <Icon ic="checked" color="success" />;
            case "N":
                return <Icon ic="negative" color="danger" />;
            default:
                return <Icon ic="hourglass" color="lighter" />;
        }
    } else {
        switch (value) {
            case "Y":
                return <Icon ic="checked" color="success" />;
            case "N":
                return <Icon ic="hourglass" color="info" />;
            default:
                return <Icon ic="negative" color="lighter" />;
        }
    }
}

export function generateChar(t, op1, op2) {
    let arr = [op1, op2];
    let random = arr[Math.floor(Math.random() * 2)]

    switch (t) {
        case 'random':
            return random
        case op1:
            return op1
        case op2:
            return op2
        default:
            return random
    }
}

export function typeStepIcon(step) {
    switch (step) {
        case "FLASHCARD":
            return "flashcards"
        case "SCORM":
            return "scorm"
        case "CHECK":
            return "check_question"
        case "EXAM":
            return "exam_open"
        case "CODE":
            return "key"
        default:
            return null;
    }
}
