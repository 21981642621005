import React from 'react';
import intl from "react-intl-universal";
import config from "../../../config";
import { numberMask, imageParser } from '../../../utils/utils';
import { ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Avatar, Typography } from '@material-ui/core';
import { Points, Flag, Medal } from '../../../components/Images/Images';
import ImgAvatarDefault from '../../../dist/img/img_avatar_default.svg';

export default function ListItemPosition(props) {
    // Extração das propriedades passadas para o componente
    const { item, module, action, userPosition } = props;

    // Variáveis que controlam os diferentes comportamentos de exibição com base na configuração do módulo
    let isDisabled = module?.ENABLED_SELECT !== 'Y'; // Verifica se a seleção está habilitada
    let hasAvatar = module?.IMAGE_USER === "Y"; // Verifica se a imagem do usuário deve ser exibida
    let hasFlag = module?.FLAG_REGION === "Y"; // Verifica se a bandeira da região deve ser exibida
    let hasName = module?.NAME_USER === "Y"; // Verifica se o nome do usuário deve ser exibido

    return (
        <ListItem
            button={isDisabled || userPosition ? false : true} // Se o item for desabilitado ou for a posição do usuário, o botão será desabilitado
            onClick={
                // Define a ação ao clicar, apenas se a seleção estiver habilitada e o item não for do próprio usuário
                module?.ENABLED_SELECT === "Y" && item.is_me === "N" ? action : null
            }
            className={`list-item-ranking ${userPosition ? "__active" : ""}`} // Adiciona uma classe de ativo se for a posição do usuário
        >

            {/* Exibe a posição do item */}
            <div className="list-item-ranking-position">
                {
                    // Se a posição for menor que 4, exibe uma medalha, caso contrário exibe o número da posição
                    item.position < 4 ?
                        <Medal type={item.position} className="img-md" />
                        :
                        <Typography variant="h3" component="p">
                            {item.position}
                        </Typography>
                }
            </div>

            {/* Se o avatar do usuário estiver habilitado, exibe a imagem do usuário */}
            <ListItemAvatar>
                <Avatar
                    src={hasAvatar || userPosition ? imageParser(item.image) : ImgAvatarDefault} // A imagem do usuário é processada pela função imageParser
                    alt={item.name} // O nome do usuário é usado como texto alternativo da imagem
                    className={userPosition ? "avatar-bordered border-white" : ""} // Adiciona borda se for o usuário
                />
            </ListItemAvatar>

            {/* Exibe o nome e pontos do usuário */}
            <ListItemText
                disableTypography // Desabilita o uso de tipografia padrão do Material UI
                primary={

                    <Typography variant="body1" component="h5">
                        {hasName || userPosition ? item.name : intl.get("LABEL_USER")}
                    </Typography>
                }
                secondary={
                    <div className="d-flex flex-row align-items-center">
                        <Points type="xp" className="img-xxs mr-1" /> {/* Ícone de pontos */}
                        <Typography className="text-xp" variant="body2" component="p">
                            {numberMask(item.qi_score)} {/* Exibe os pontos formatados */}
                        </Typography>
                    </div>
                }
            />

            {/* Exibe a bandeira, se configurado */}
            {
                hasFlag ?
                    // Exibe a bandeira do estado ou do país, conforme a configuração
                    config.ranking.flag_type === "states" && (item.country && item.country === "BR") && (item.state && item.state.length === 2) ?
                        <ListItemSecondaryAction>
                            <Flag
                                type="states"
                                code={item.state.toLowerCase()} // O código da bandeira é derivado do estado
                                className="img-xxs"
                            />
                        </ListItemSecondaryAction>
                        :
                        config.ranking.flag_type === "country" && (item.country && item.country.length === 2) ?
                            <ListItemSecondaryAction>
                                <Flag
                                    type="country"
                                    code={item.country.toLowerCase()} // O código da bandeira é derivado do país
                                    className="img-xxs"
                                />
                            </ListItemSecondaryAction>
                            : null
                    : null
            }
        </ListItem>
    );
}
