import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { imageParser } from '../../../utils/utils';
import {
    Container, Grid, CardActionArea, CardMedia, Typography,
    Slide, IconButton, Dialog, DialogContent, DialogTitle
} from '@material-ui/core';
import QrImage from '../../QrImage';
import { Icon } from '../../Images/Images';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GameHeader = ({ children, data, questionCustom }) => {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div className="qr-game-question appbar-custom-content">
            <Container maxWidth="md">
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} sm={10}>
                        {
                            // Layout personalizado para cada tipo de módulo da plataforma
                            children
                        }
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <Grid container className="qr-game-question-content" alignItems="center" justify="center" spacing={2}>
                            {
                                // Se houver imagem e NÃO for abaixo da pergunta
                                (data.question.question_type !== "QUIZ_IMAGE_DOWN" && (data.question.image && Object.keys(data.question.image).length !== 0)) &&
                                <Grid item xs={12} sm={10}>
                                    <CardActionArea
                                        onClick={handleClickOpen}
                                        className="rounded-lg"
                                    >
                                        <div style={{
                                            position: 'relative', paddingBottom: '75%',  // Define a proporção 4:3
                                            height: 0, overflow: 'hidden'
                                        }}>
                                            <CardMedia
                                                component="img"
                                                image={imageParser(data.question.image)}
                                                className="image-question img-fluid"
                                                title="Question"
                                                style={{
                                                    position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' // Faz a imagem preencher o espaço sem distorção
                                                }}
                                            />
                                        </div>
                                    </CardActionArea>
                                </Grid>
                            }

                            {/* Texto da pergunta */}
                            <Grid item xs={12}>
                                <Typography variant="h5" component="div">
                                    {ReactHtmlParser(data.question.question)}
                                </Typography>
                            </Grid>

                            {
                                // Se houver imagem e for abaixo da pergunta
                                (data.question.question_type === "QUIZ_IMAGE_DOWN" && (data.question.image && Object.keys(data.question.image).length !== 0)) &&
                                <Grid item xs={12} sm={10}>
                                    <CardActionArea
                                        onClick={handleClickOpen}
                                        className="rounded-lg"
                                    >
                                        <div style={{
                                            position: 'relative', paddingBottom: '75%',  // Define a proporção 4:3
                                            height: 0, overflow: 'hidden'
                                        }}>
                                            <CardMedia
                                                component="img"
                                                image={imageParser(data.question.image)}
                                                className="image-question img-fluid"
                                                title="Question"
                                                style={{
                                                    position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' // Faz a imagem preencher o espaço sem distorção
                                                }}
                                            />
                                        </div>
                                    </CardActionArea>
                                </Grid>
                            }

                            {
                                // Se houver customização de algum módulo de perguntas
                                questionCustom &&
                                <Grid container className="qr-game-question-custom" justify="center" spacing={2}>
                                    {questionCustom}
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            {
                data.question.image &&
                <Dialog id="dialog-image-question" fullWidth maxWidth="md" open={open} onClose={handleClose} TransitionComponent={Transition}>
                    <DialogTitle>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <Icon ic="close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container justify="center">
                            <Grid item xs={12} align="center">
                                <QrImage
                                    src={imageParser(data.question.image)}
                                    effect="blur"
                                    className="img-fluid"
                                    alt="Question"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
        </div>
    )
}

export default GameHeader