import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from "../../config";
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as RankingActions } from '../../redux/ducks/ranking';
import { imageParser, numberMask } from '../../utils/utils';
import {
    AppBar, Toolbar, IconButton, Typography, useMediaQuery,
    Tabs, Tab, Container, Grid, Button, List, Divider, CardActionArea
} from '@material-ui/core';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import EmptyContent from '../../components/EmptyContent';
import ErrorContent from '../../components/ErrorContent';
import ListItemPosition from './components/ListItemPosition';
import { LoadingBounce, LoadingAvatarList } from '../../components/Loading';
import { Icon, Medal, Flag, Points } from '../../components/Images/Images';
import ImgAvatarDefault from '../../dist/img/img_avatar_default.svg';

// Função auxiliar para configurar propriedades dos tabs
function tabsProps(index) {
    return {
        id: `appbar-tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

// Componente para renderizar o conteúdo de cada tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Grid container
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            className="qr-tabpanel"
            {...other}
        >
            {value === index && children}
        </Grid>
    );
}

export default function Ranking() {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const { page, limit, ranking } = useSelector(state => state.ranking);
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const qrRanking = JSON.parse(localStorage.getItem('auth.state.modules')).RANKING;

    // Estados internos do componente
    const [value, setValue] = React.useState(0);
    const [didMount, setDidMount] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [themeMode] = useState(auth.user.layout_theme || config.layout.theme);
    const [filter, setFilter] = useState(qrRanking?.FILTER === 'Y' ? 'day' : '');
    const [type, setType] = useState(qrRanking?.TAB_GENERAL === 'Y' ? 'general' : qrRanking?.TAB_FOLLOWING === 'Y' ? 'friend' : 'group');
    const tabs = [];
    const bottomMenuItem = config.layout.menu.nav_bottom_order.includes('MENU_RANKING');
    const hasData = ranking && ranking.load === "finished" && ranking.data.length !== 0;

    // Verifica autenticação e realiza redirecionamento
    useEffect(() => {
        if (!auth.authenticated) {
            history.replace('/');
        }
    }, [auth, history]);

    // Realiza a busca inicial e configura limpeza ao desmontar o componente
    useEffect(() => {
        setDidMount(true);
        if (!fetching && !hasData) {
            setFetching(true);
            dispatch(
                RankingActions.getRanking({
                    page: 1,
                    limit,
                    ranking_type: type,
                    ranking_period: filter,
                })
            );
        }

        return () => {
            if (didMount && history.location.pathname !== "/ranking") {
                dispatch(RankingActions.reset());
            }
            setDidMount(false);
        };
    }, [history, didMount, dispatch, page, limit, fetching, type, filter, hasData]);

    // Atualiza o tipo de ranking ao trocar de tab
    function getTabData(tabName) {
        setFilter("day");
        dispatch(RankingActions.reset());

        switch (tabName) {
            case intl.get("RANKING_TAB_GENERAL_TITLE"):
                setType('general');
                break;
            case intl.get("RANKING_TAB_FOLLOWING_TITLE"):
                setType('friend');
                break;
            case intl.get("RANKING_TAB_GROUP_TITLE"):
                setType('group');
                break;
            default:
        }
        setFetching(false);
    }

    // Configura tabs baseadas nas permissões
    if (qrRanking?.TAB_GENERAL === 'Y') tabs.push(intl.get("RANKING_TAB_GENERAL_TITLE"));
    if (qrRanking?.TAB_FOLLOWING === 'Y') tabs.push(intl.get("RANKING_TAB_FOLLOWING_TITLE"));
    if (qrRanking?.TAB_GROUP === 'Y') tabs.push(intl.get("RANKING_TAB_GROUP_TITLE"));

    // Filtra o ranking por período
    async function handleFilter(period) {
        await dispatch(RankingActions.reset());
        setFilter(period === "all" ? "" : period);

        await dispatch(
            RankingActions.getRanking({
                page: 1,
                limit,
                ranking_type: type,
                ranking_period: period === "all" ? "" : period,
            })
        );
    }

    // Carrega a próxima página do ranking
    async function nextPage() {
        const pageNumber = page + 1;
        setLoading(true);
        await dispatch(RankingActions.getLoadMore({
            page: pageNumber,
            limit,
            ranking_type: type,
            ranking_period: filter,
        }));
        await dispatch(RankingActions.setPage({ page: pageNumber }));
        setLoading(false);
    }

    // Preenche posições vazias para o Top 3
    function completeTop3(total) {
        return Array.from({ length: total }, (_, i) => (
            <Grid key={i} item xs={4} className={`${total === 2 && i === 0 ? "order-1" : "order-3"} __empty`}></Grid>
        ));
    }

    // Lida com a troca de tabs
    const handleChange = (event, newValue) => {
        setValue(newValue);
        getTabData(tabs[newValue]);
    };

    // Renderiza a AppBar
    const QrAppBar = () => (
        <AppBar
            className={`appbar appbar-${bottomMenuItem || isScreenUpMd ? "main" : "default"}${tabs.length > 1 ? " __tabs" : ""}`}
            position={`${bottomMenuItem || isScreenUpMd ? "static" : "sticky"}`}
            color="primary"
        >
            <Toolbar>
                {config.layout.navbar && !bottomMenuItem && !isScreenUpMd && (
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                )}
                <div className="w-100 overflow-hidden">
                    <Typography variant="h4" noWrap>
                        {intl.get('RANKING_TITLE')}
                    </Typography>
                    {(bottomMenuItem || isScreenUpMd) && (
                        <div className="w-100 d-flex overflow-hidden">
                            <Typography variant="body2" noWrap>
                                {intl.get("RANKING_SUBTITLE")}
                            </Typography>
                        </div>
                    )}
                </div>
            </Toolbar>

            {tabs.length > 1 && (
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="tabs"
                    variant={isScreenUpMd ? "standard" : "fullWidth"}
                    className="appbar-default-tabs"
                    centered
                >
                    {tabs.map((tab, key) => <Tab key={key} label={tab} {...tabsProps(key)} />)}
                </Tabs>
            )}
        </AppBar>
    );

    const RenderRanking = () => {
        return (
            <Grid container className={qrRanking?.FILTER === 'Y' ? "pt-3" : ""}>

                {/* Se a configuração de ranking estiver habilitada, exibe os filtros */}
                {qrRanking?.FILTER === 'Y' ? (
                    <Container maxWidth="md" className="pb-3">
                        <Grid container justify="center" spacing={2}>
                            {/* Botões de filtro para diferentes períodos */}
                            {['day', 'week', 'month', 'all'].map((period) => (
                                <Grid item xs={6} md={3} key={period}>
                                    <Button
                                        variant={filter === period ? "contained" : "outlined"}
                                        onClick={() => handleFilter(period)}
                                        color="primary"
                                        className="btn-tabpanel btn-block"
                                    >
                                        {intl.get(`LABEL_${period === "day" ? "TODAY" : period.toUpperCase()}`)}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                ) : null}

                {/* Se a tela for maior que 'md' (tamanho grande), renderiza o ranking em uma grade */}
                {isScreenUpMd ? (
                    <Grid container>
                        <Container maxWidth="md">
                            <Grid container justify="center" spacing={2} className="top3">
                                {/* Mapeia os 3 primeiros colocados */}
                                {ranking.data
                                    .filter((pos) => parseInt(pos.position) < 4)
                                    .map((item, index) => (
                                        <Grid
                                            key={index}
                                            item
                                            xs={4}
                                            className={
                                                parseInt(item.position) === 1
                                                    ? "order-2"
                                                    : parseInt(item.position) === 2
                                                        ? "order-1"
                                                        : "order-3"
                                            }
                                        >
                                            <CardActionArea
                                                disabled={
                                                    (qrRanking?.ENABLED_SELECT !== "Y") ||
                                                        item.is_me === "Y" ?
                                                        true : false
                                                }
                                                onClick={() =>
                                                    history.push({
                                                        pathname: `${history.location.pathname.replace(/./g, "")}/profile/${item.id_user}`,
                                                        state: {
                                                            origin: "ranking",
                                                        },
                                                    })
                                                }
                                            >
                                                {/* Avatar do usuário e medalha */}
                                                <div className={`top-avatar${qrRanking?.IMAGE_USER === "N" ? " __no-avatar" : ""}`}>
                                                    <img
                                                        src={qrRanking?.IMAGE_USER === "Y" || item.is_me === "Y" ? imageParser(item.image) : ImgAvatarDefault}
                                                        alt={item.name}
                                                        className="avatar"
                                                    />
                                                    <div className="medal">
                                                        <Medal type={item.position} className="img-lg" />
                                                    </div>
                                                </div>

                                                {/* Nome do usuário e informações adicionais */}
                                                <div className="top-name">
                                                    {qrRanking?.FLAG_REGION === "Y"
                                                        ? config.ranking.flag_type === "states" &&
                                                            item.country === "BR" &&
                                                            item.state?.length === 2 ? (
                                                            <Flag
                                                                type="states"
                                                                code={item.state.toLowerCase()}
                                                                className="img-xxs mr-2"
                                                            />
                                                        ) : config.ranking.flag_type === "country" &&
                                                            item.country?.length === 2 ? (
                                                            <Flag
                                                                type="country"
                                                                code={item.country.toLowerCase()}
                                                                className="img-xxs mr-2"
                                                            />
                                                        ) : null
                                                        : null}
                                                    <Typography
                                                        variant="body1"
                                                        component="p"
                                                        className={item.is_me === "Y" ? "text-heading" : ""}
                                                    >
                                                        {qrRanking?.NAME_USER === "Y" || item.is_me === "Y" ? item.name : intl.get("LABEL_USER")}
                                                    </Typography>
                                                </div>

                                                {/* Pontuação do usuário */}
                                                <div className="top-points">
                                                    <Points type="xp" className="img-xxs mr-1" />
                                                    <Typography className="text-xp" variant="body2" component="p">
                                                        {numberMask(item.qi_score)}
                                                    </Typography>
                                                </div>
                                            </CardActionArea>
                                        </Grid>
                                    ))}
                                {/* Se houver menos de 3 itens, completa com o ranking vazio */}
                                {ranking.data.length <= 2 ? completeTop3(3 - ranking.data.length) : null}
                            </Grid>
                        </Container>

                        {/* Se o número de dados for maior que 3, exibe a lista */}
                        {ranking.data.length > 3 ? (
                            <Container maxWidth="md" className="container-list">
                                <List>
                                    <Divider />
                                    {config.ranking.max_positions === 0
                                        ? ranking.data
                                            .filter((pos) => parseInt(pos.position) > 3)
                                            .map((item, index) => (
                                                <div key={index}>
                                                    <ListItemPosition
                                                        module={qrRanking}
                                                        item={item}
                                                        userPosition={item.is_me === "Y"}
                                                        action={() => history.push({
                                                            pathname: `${history.location.pathname.replace(/./g, "")}/profile/${item.id_user}`,
                                                            state: {
                                                                origin: "ranking",
                                                            },
                                                        })}
                                                    />
                                                    <Divider />
                                                </div>
                                            ))
                                        : ranking.data
                                            .filter((pos) => parseInt(pos.position) > 3)
                                            .slice(0, config.ranking.max_positions - 3)
                                            .map((item, index) => (
                                                <div key={index}>
                                                    <ListItemPosition
                                                        module={qrRanking}
                                                        item={item}
                                                        userPosition={item.is_me === "Y"}
                                                        action={() => history.push({
                                                            pathname: `${history.location.pathname.replace(/./g, "")}/profile/${item.id_user}`,
                                                            state: {
                                                                origin: "ranking",
                                                            },
                                                        })}
                                                    />
                                                    <Divider />
                                                </div>
                                            ))}
                                    {/* Se o usuário não estiver no ranking, mostra a posição dele */}
                                    {ranking.data.filter((me) => me.is_me === "Y").length === 0 ? (
                                        <ListItemPosition module={qrRanking} item={ranking.me} userPosition={true} />
                                    ) : null}
                                </List>
                            </Container>
                        ) : null}
                    </Grid>
                ) : (
                    <Container maxWidth="md" className="container-list">
                        <Grid container justify="center">
                            <Grid item xs={12}>
                                <List>
                                    {config.ranking.max_positions === 0 ?
                                        ranking.data.map((item, index) => (
                                            <div key={index}>
                                                <ListItemPosition
                                                    module={qrRanking}
                                                    item={item}
                                                    userPosition={item.is_me === "Y"}
                                                    action={() => history.push({
                                                        pathname: `${history.location.pathname.replace(/./g, "")}/profile/${item.id_user}`,
                                                        state: {
                                                            origin: "ranking",
                                                        },
                                                    })}
                                                />
                                                <Divider />
                                            </div>
                                        ))
                                        : ranking.data
                                            .slice(0, config.ranking.max_positions)
                                            .map((item, index) => (
                                                <div key={index}>
                                                    <ListItemPosition
                                                        module={qrRanking}
                                                        item={item}
                                                        userPosition={item.is_me === "Y"}
                                                        action={() => history.push({
                                                            pathname: `${history.location.pathname.replace(/./g, "")}/profile/${item.id_user}`,
                                                            state: {
                                                                origin: "ranking",
                                                            },
                                                        })}
                                                    />
                                                    <Divider />
                                                </div>
                                            ))}
                                    {/* Se o usuário não estiver no ranking, mostra a posição dele */}
                                    {ranking.data.filter((me) => me.is_me === "Y").length === 0 ? (
                                        <ListItemPosition module={qrRanking} item={ranking.me} userPosition={true} />
                                    ) : null}
                                </List>
                            </Grid>
                        </Grid>
                    </Container>
                )}

                {/* Se o número de posições for menor que o total de ranking, exibe o botão de carregar mais */}
                {config.ranking.max_positions === 0 || ranking.data.length < config.ranking.max_positions ? (
                    parseInt(ranking.total) > ranking.data.length ? (
                        <Container maxWidth="md" className="pt-3 pb-4">
                            <Grid container justify="center">
                                <Grid item xs={12} sm={6} lg={4} align="center">
                                    {loading ? (
                                        <LoadingBounce />
                                    ) : (
                                        <Button onClick={nextPage} variant="outlined" color="primary" className="btn-block">
                                            {intl.get("BTN_LOAD_MORE")}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Container>
                    ) : null
                ) : null}
            </Grid>
        )
    }

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            {auth.authenticated && (
                <div id="qr-wrap">
                    <QrMenu />
                    <div id="qr-content">
                        <QrContent id="ranking" bottomNavigation={bottomMenuItem} hideSidebar>
                            {/* AppBar do sistema */}
                            <QrAppBar />

                            <div className={`${bottomMenuItem ? "page-bottom-menu" : ""}`}>
                                {tabs.map((title, index) => (
                                    <TabPanel key={index} value={value} index={index}>
                                        {/* Se o ranking estiver carregado, renderiza a lista ou um conteúdo vazio */}
                                        {ranking && ranking.load === "finished" ? (
                                            !ranking.error ? (
                                                ranking.data.length !== 0 ? (
                                                    <RenderRanking />
                                                ) : (
                                                    <Container maxWidth="md" className="pt-3 pb-3">
                                                        <EmptyContent
                                                            icon={<Icon ic="trophy_filled" className="icon-xxl" />}
                                                            text={intl.get("EMPTY_RANKING")}
                                                        />
                                                    </Container>
                                                )
                                            ) : (
                                                <Container maxWidth="md" className="pt-3 pb-3">
                                                    <ErrorContent />
                                                </Container>
                                            )
                                        ) : (
                                            <Container maxWidth="md" className="pt-3 pb-3">
                                                <LoadingAvatarList limit={10} />
                                            </Container>
                                        )}
                                    </TabPanel>
                                ))}
                            </div>
                        </QrContent>
                    </div>
                </div>
            )}
        </div>
    );
}