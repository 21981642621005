import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { imageParser } from '../../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { actions as AchievementsActions } from '../../../redux/ducks/achievements';
import {
    Grid, Dialog, Button, DialogContent, Typography, IconButton,
    useMediaQuery, List, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import { Icon } from '../../../components/Images/Images';
import ImgBadgeStars from '../../../dist/img/svgr/ImgBadgeStars';
import QrImage from '../../../components/QrImage';
import PointsBar from '../../../components/PointsBar';
import { LoadingBounce } from '../../../components/Loading';
import SnackBar from '../../../components/snackBar';

const DialogMissionsDetails = ({ data, open, close, transition, details }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const missionRedeem = useSelector(state => state.achievements.missionRedeem);
    const [loading, setLoading] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [buttonId, setButtonId] = useState("");
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));

    const DialogTitle = ({ onClose }) => {
        return (
            <div className="achievements-details-content-title">
                <div className="__empty"></div>
                {
                    !details &&
                    <div className="w-100">
                        <Typography variant="h3" component="h2" align="center" className="text-primary">
                            {intl.get('ACHIEVEMENTS_DIALOG_TITLE')}
                        </Typography>
                        <Typography variant="body2" component="p" align="center">
                            {intl.get('ACHIEVEMENTS_DIALOG_TEXT')}
                        </Typography>
                    </div>
                }
                {onClose ? (
                    <IconButton aria-label="close" onClick={onClose} className="__button">
                        <Icon ic="close" />
                    </IconButton>
                ) : null}
            </div>
        );
    };

    const setMissionRedeem = async (id) => {
        setButtonId(id);
        setLoading(true);
        dispatch(AchievementsActions.setMissionRedeem({
            id_progress: id
        }));
    }

    useEffect(() => {
        if (loading) {
            if (!missionRedeem?.error && missionRedeem?.data?.achievements) {
                setLoading(false);
                user.neuros = missionRedeem.data.user.neuros;
                user.qi_credit = missionRedeem.data.user.qis;
                user.total_points = missionRedeem.data.user.xps;
                localStorage.setItem('auth.state.user', JSON.stringify(user));
                data.image = missionRedeem.data.achievements.image;
                data.completed = missionRedeem.data.achievements.completed;
                data.levels.forEach((level) => {
                    if (level.id_progress === missionRedeem.data.achievements.id_progress) {
                        level.completed = "Y";
                        level.is_redeemed = "Y";
                        level.redeem_available = "N";
                    }
                });

                dispatch(AchievementsActions.setMissionUpdate({
                    total_user: missionRedeem.data.total_list?.total_user
                }));

                dispatch(AchievementsActions.resetMissionRedeem({}));
            } else if (missionRedeem?.error === true) {
                setSnackOpen(true);
                setLoading(false);
                dispatch(AchievementsActions.resetMissionRedeem({}));
            }
        }
    }, [dispatch, loading, missionRedeem, user, data]);

    return (
        <Dialog
            id="dialog-achievements-details"
            fullScreen={!isScreenUpMd}
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={close}
            scroll="body"
            aria-labelledby="responsive-dialog-title"
            TransitionComponent={transition}
        >
            <DialogContent className={`achievements-details-content${!details || data.completed === "Y" ? " __active" : ""}${details ? " __details" : ""} __missions`}>
                <DialogTitle onClose={close} />

                <div className={`achievements-details-content-image${details ? " __details" : ""}`}>
                    <div className="achievements-image">
                        <QrImage
                            src={data.image && imageParser(data.image)}
                            effect="blur"
                            className="__image"
                            alt="Missions"
                        />
                    </div>
                    {
                        !details || data.completed === "Y" ?
                            <ImgBadgeStars className="achievements-stars" />
                            :
                            null
                    }
                </div>
                <div className="achievements-details-content-info">
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={12} align="center">
                            <Typography variant="h4" component="p" className="text-dark">
                                {data.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <List component="div">
                                {
                                    data?.levels?.map((item, index) => (
                                        <ListItem key={index} className={`level-item${item.completed === "Y" && item.is_redeemed === "Y" ? " __completed" : ""}`} component="div">
                                            <div className="level-item-content">
                                                <ListItemIcon>
                                                    <Icon
                                                        ic={`checkbox_${item.completed === "Y" && item.is_redeemed === "Y" ? "checked" : "unchecked"}`}
                                                        color={`${item.completed === "Y" ? "primary" : "lighter"}`}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={item.description}
                                                />
                                                {
                                                    item.redeem_available === "Y" ?
                                                        !loading ?
                                                            <Button
                                                                id="btn_achievements_redeem"
                                                                variant="contained"
                                                                onClick={() => setMissionRedeem(item.id_progress)}
                                                            >
                                                                {intl.get('BTN_REDEEM')}
                                                            </Button>
                                                            :
                                                            buttonId === item.id_progress ?
                                                                <LoadingBounce size={24} />
                                                                : null
                                                        : item.is_redeemed === "Y" ?
                                                            <div className="achievements-redeemed mt-0">
                                                                <Icon ic="check" className="icon-xxs mr-1" />
                                                                <Typography variant="body2" component="p" className="font-weight-bold">
                                                                    {intl.get('LABEL_REDEEMED')}
                                                                </Typography>
                                                            </div>
                                                            : null
                                                }
                                            </div>
                                            <div className="level-item-points">
                                                <Typography variant="caption" component="p" className="ml-2">
                                                    {intl.get('LABEL_REWARDS')}:
                                                </Typography>
                                                <div className="__points">
                                                    {
                                                        item.xps && item.xps > 0 ?
                                                            <PointsBar type="xp" value={item.xps} className="ml-2 mr-2" />
                                                            : null
                                                    }
                                                    {
                                                        item.qis && item.qis > 0 ?
                                                            <PointsBar type="qis" value={item.qis} className="ml-2 mr-2" />
                                                            : null
                                                    }
                                                    {
                                                        item.neuros && item.neuros > 0 ?
                                                            <PointsBar type="neuros" value={item.neuros} className="ml-2 mr-2" />
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <SnackBar
                open={snackOpen}
                message={intl.get('ERROR_UPDATE_DATA')}
                status="error"
                time={3}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                closeSnack={() => setSnackOpen(false)}
            />
        </Dialog>
    )
}

export default DialogMissionsDetails