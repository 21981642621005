import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import { actions as PublicActions } from '../../redux/ducks/public';
import { getDateTimeTimezone, getTimer } from '../../utils/utils';
import ReactHtmlParser from 'react-html-parser';
import {
    Container, Grid, Typography, List, ListItem, ListItemText,
    Divider
} from '@material-ui/core';
import EmptyContent from '../../components/EmptyContent';
import ErrorContent from '../../components/ErrorContent';
import { LoadingContent } from "../../components/Loading";
import { Logo, Icon } from '../../components/Images/Images';
import ImgHeaderDiploma from '../../dist/img/svgr/ImgHeaderDiploma';

export default function CertificateAuthentication(props) {
    const dispatch = useDispatch();
    const certificate = useSelector(state => state.public.certificate);
    const [didMount, setDidMount] = useState(false);

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(PublicActions.getAuthCertificate({
                id_user: props.match.params.idUser,
                type_course: props.match.params.typeCourse,
                id_course: props.match.params.idCourse,
                id_user_course: props.match.params.idUserCourse,
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(PublicActions.reset());
        }
    }, [didMount, dispatch, props.match]);

    return (
        <div id="public" className="auth-certificate">
            <Container maxWidth="lg" className="pt-3 pb-4">
                <Grid container justify="center" className="pb-3">
                    <Grid item xs={12} align="center">
                        <Logo type="main-color" className="logo-main" />
                    </Grid>
                </Grid>
                {
                    certificate && certificate.load === "finished" ?
                        !certificate.error ?
                            certificate.data.length !== 0 ?
                                <Grid container justify="center" spacing={2}>
                                    <Grid item xs={12} sm={10} lg={8} align="center" className="mt-2">
                                        <ImgHeaderDiploma />
                                    </Grid>
                                    <Grid item xs={12} sm={10} lg={8} align="center">
                                        <Typography variant="h3" component="h1" className="text-dark">
                                            {intl.get("CERTIFICATE_AUTH_TITLE")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={10} lg={8}>
                                        <List>
                                            {
                                                certificate.data.user_name ?
                                                    <>
                                                        <ListItem disableGutters>
                                                            <ListItemText
                                                                primary={intl.get("LABEL_NAME")}
                                                                secondary={certificate.data.user_name}
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                    </>
                                                    : null
                                            }
                                            {
                                                certificate.data.course_title ?
                                                    <>
                                                        <ListItem disableGutters>
                                                            <ListItemText
                                                                primary={intl.get("LABEL_COURSE")}
                                                                secondary={certificate.data.course_title}
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                    </>
                                                    : null
                                            }
                                            {
                                                certificate.data.date ?
                                                    <>
                                                        <ListItem disableGutters>
                                                            <ListItemText
                                                                primary={intl.get("LABEL_ISSUE_DATE")}
                                                                secondary={getDateTimeTimezone(certificate.data.date)}
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                    </>
                                                    : null
                                            }
                                            {
                                                certificate.data.completion_date ?
                                                    <>
                                                        <ListItem disableGutters>
                                                            <ListItemText
                                                                primary={intl.get("LABEL_COMPLETION_DATE")}
                                                                secondary={getDateTimeTimezone(certificate.data.completion_date)}
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                    </>
                                                    : null
                                            }
                                            {
                                                certificate.data.course_workload ?
                                                    <>
                                                        <ListItem disableGutters>
                                                            <ListItemText
                                                                primary={intl.get("LABEL_WORKLOAD")}
                                                                secondary={getTimer(certificate.data.course_workload)}
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                    </>
                                                    : null
                                            }
                                            {
                                                certificate.data.due_date ?
                                                    <>
                                                        <ListItem disableGutters>
                                                            <ListItemText
                                                                primary={intl.get("LABEL_CERTIFICATE_VALIDITY")}
                                                                secondary={certificate.data.due_date}
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                    </>
                                                    : null
                                            }
                                            {
                                                certificate.data.course_program_content ?
                                                    <>
                                                        <ListItem disableGutters>
                                                            <ListItemText disableTypography
                                                                primary={
                                                                    <Typography variant="body1" component="p" className="fs-sm">
                                                                        {intl.get("LABEL_PROGRAM_CONTENT")}
                                                                    </Typography>
                                                                }
                                                                secondary={
                                                                    <Typography variant="body2" component="div" className="fs-md">
                                                                        {ReactHtmlParser(certificate.data.course_program_content)}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                    </>
                                                    : null
                                            }
                                        </List>
                                    </Grid>
                                </Grid>
                                :
                                <EmptyContent icon={<Icon ic="diploma" className="icon-xxl" />} text={intl.get("EMPTY_CERTIFICATE")} />
                            :
                            <ErrorContent />
                        :
                        <LoadingContent />
                }
            </Container>
        </div >
    )
}