import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import { libraryService } from '../../services/library.service';
import { useSelector, useDispatch } from 'react-redux';
import { actions as libraryActions } from '../../redux/ducks/library';
import { goToTopAnimation } from '../../utils/utils';
import useReactRouter from 'use-react-router';
import {
    Grid, Slide, List, Divider, AppBar, InputBase,
    Toolbar, IconButton, Typography, Container
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import DialogFile from './dialog.file';
import FileItem from './components/FileItem';
import ErrorContent from '../../components/ErrorContent';
import EmptyContent from '../../components/EmptyContent';
import SnackBar from '../../components/snackBar';
import { Icon } from '../../components/Images/Images';
import { LoadingContent, LoadingAvatarList } from '../../components/Loading';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FileList(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const fileList = useSelector(state => state.library.fileList);
    const file = useSelector(state => state.library.file);
    const [didMount, setDidMount] = useState(false);
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [openFile, setOpenFile] = useState(false);
    const [loadFile, setLoadFile] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [filteredFileList, setFilteredFileList] = useState(null);
    const [search, setSearch] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const category = props.location.state && props.location.state.category ? props.location.state.category : '';
    const itemsPerPage = 10;

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(libraryActions.getFileListCategory({
                page: 1,
                limit: 0,
                order: 3,
                id_file_category: props.match.params.idCategory
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(libraryActions.reset());
        }
    }, [didMount, dispatch, props.match]);

    useEffect(() => {
        setFilteredFileList(null);

        async function fetchDataFiltered() {
            let list = await libraryService.getFileList({
                page: 1,
                limit: 0,
                order: 3,
                id_file_category: props.match.params.idCategory,
                pattern: search
            });
            setFilteredFileList(list);
        }

        const timer = setTimeout(() => {
            if (search !== "") {
                fetchDataFiltered()
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [search, props.match.params.idCategory]);

    async function openDialog(item) {
        setLoadFile(true);
        await dispatch(libraryActions.getFile({
            id_file: item.id_file
        }));
        setOpenFile(true);
    }

    if (openFile && (file && file.load === "finished")) {
        if (!file.error && file.data.length !== 0) {
            if (file.data.type === "LINK") {
                window.open(file.data.source, '_blank');
            } else {
                setOpen(true);
            }
        } else {
            setSnackOpen(true);
        }
        setOpenFile(false);
        setLoadFile(false);
    }

    function goBack() {
        let currentOrigin = origin === "list_learn" ? "files_learn" : "files";

        switch (origin) {
            case 'list':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/library`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'list_learn':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/library`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {category}
                        </Typography>
                    </div>
                    {
                        searchOpen ?
                            <div className="appbar-search">
                                <InputBase
                                    className="appbar-search-bar"
                                    fullWidth
                                    placeholder={intl.get("SEARCH_COMPONENT")}
                                    autoFocus
                                    color='primary'
                                    variant="filled-basic"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <IconButton onClick={() => { setSearch(''); setFilteredFileList(null); setSearchOpen(false) }} className="ml-2">
                                    <Icon ic="cancel" />
                                </IconButton>
                            </div>
                            :
                            <IconButton onClick={() => setSearchOpen(!searchOpen)}>
                                <Icon ic="search" />
                            </IconButton>
                    }
                </Toolbar>
            </AppBar>
        )
    }

    const FileList = () => {
        return (
            <List component="nav" className="w-100">
                {
                    fileList.data
                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map((file, index) => {
                            return (
                                <div key={index}>
                                    <FileItem
                                        file={file}
                                        bgColor={file.bgcolor}
                                        blob={file.blob}
                                        content={file.content}
                                        title={file.title}
                                        description={file.description}
                                        date={file.file_date}
                                        type={file.type}
                                        openDialog={openDialog}
                                    />
                                    <Divider variant="inset" />
                                </div>
                            )
                        })
                }
            </List>
        )
    }

    const FilteredFiles = () => {
        if (filteredFileList && filteredFileList.files.length > 0) {
            return (
                <List component="nav" className="w-100">
                    {
                        filteredFileList.files.map((file, index) => {
                            return (
                                <div key={index}>
                                    <FileItem
                                        file={file}
                                        bgColor={file.bgcolor}
                                        blob={file.blob}
                                        content={file.content}
                                        title={file.title}
                                        description={file.description}
                                        date={file.file_date}
                                        type={file.type}
                                        openDialog={openDialog}
                                    />
                                    <Divider variant="inset" />
                                </div>
                            )
                        })
                    }
                </List>
            )
        } else if (filteredFileList && filteredFileList.files.length === 0) {
            return (
                <EmptyContent icon={<Icon ic="search" className="icon-xxl" />} text={intl.get("NO_RESULTS_FOUND")} />
            );
        }

        return (
            <LoadingContent />
        )
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="library" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    <Container maxWidth="md" className="container-list pt-2 pb-4">
                        {
                            !loadFile ?
                                fileList && fileList.load === "finished" ?
                                    !fileList.error ?
                                        fileList.data.length !== 0 ?
                                            <>
                                                {
                                                    search.length !== 0 ?
                                                        <FilteredFiles />
                                                        :
                                                        <>
                                                            <FileList />
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Pagination
                                                                        count={Math.ceil(fileList.data.length / itemsPerPage)}
                                                                        page={page}
                                                                        onChange={(e, value) => { setPage(value); goToTopAnimation(); }}
                                                                        defaultPage={1}
                                                                        color="primary"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                }
                                                {
                                                    (file && file.load === "finished") && (!file.error && file.data.length !== 0) ?
                                                        <DialogFile
                                                            open={open}
                                                            close={() => setOpen(false)}
                                                            transition={Transition}
                                                            title={file.data.title}
                                                            file={file.data}
                                                        />
                                                        : null
                                                }
                                            </>
                                            :
                                            <EmptyContent icon={<Icon ic="book_stack_filled" className="icon-xxl" />} text={intl.get("EMPTY_LIBRARY")} />
                                        :
                                        <ErrorContent />
                                    :
                                    <LoadingAvatarList limit={10} />
                                :
                                <LoadingContent />
                        }
                    </Container>
                    <SnackBar
                        open={snackOpen}
                        message={intl.get('ERROR_LOAD_FILE')}
                        status="error"
                        time={4}
                        closeSnack={() => setSnackOpen(false)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    />
                </QrContent>
            </div>
        </div>
    )
}
