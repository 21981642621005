import React from 'react';
import intl from "react-intl-universal";
import useReactRouter from 'use-react-router';
import config from '../../../../config';
import { getDateTimeTimezone, typeStepIcon } from '../../../../utils/utils';
import { Grid, Button, Typography, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@material-ui/core';
import { Icon, StageMedal } from '../../../../components/Images/Images';

export default function ButtonSubcategory(props) {
    const { action, item, idTrail } = props;
    const { history } = useReactRouter();
    let complete = item.complete === "Y";
    let unlock = item.available === "Y";

    const Level = ({ cleared, grade, type, number }) => {
        let clearedStep = cleared === 'Y';

        const TypeStep = () => {
            switch (type) {
                case "FLASHCARD":
                    return (
                        <Typography variant="h3" component="h3">
                            {number}
                        </Typography>
                    )
                case "SCORM":
                    return (
                        <Typography variant="h3" component="h3">
                            {number}
                        </Typography>
                    )
                case "CHECK":
                    return (
                        <Typography variant="h3" component="h3">
                            {number}
                        </Typography>
                    )
                case "EXAM":
                    return (
                        <Icon ic="pencil" />
                    )
                case "CODE":
                    return (
                        <Icon ic="key" />
                    )
                default:
                    return null;
            }
        }

        return (
            <div className={`step-content-medal ${clearedStep ? "__cleared" : "__bordered"}`}>
                {
                    clearedStep ?
                        <>
                            <StageMedal
                                className="medal"
                                type={
                                    grade === 1 ?
                                        "bronze"
                                        :
                                        grade === 2 ?
                                            "silver"
                                            :
                                            grade === 3 ?
                                                "gold"
                                                : null
                                }
                            />
                            <div className={`step-content-medal-number${config.learn.trail.type === "step" ? " __clean" : ""}`}>
                                <TypeStep />
                            </div>
                        </>
                        :
                        <TypeStep />
                }
            </div>
        )
    }

    const StepButton = ({ step }) => {
        let unlock = step.available === "Y";

        return (
            <div className={`step-content ${!unlock ? "__disabled" : "__active"}`} onClick={() => unlock && history.push({
                pathname: `/trail/${idTrail}/${item.id_stage}/intro`,
                state: {
                    step: step,
                    origin: 'details'
                }
            })}>
                <Level cleared={step.step_cleared} grade={parseInt(step.cleared_grade)} type={step.step_type} number={step.step_number} />
                <div className="step-content-text">
                    <div>
                        <Typography variant="caption" component="p">
                            {step.step_number_description}
                        </Typography>
                        <Typography variant="body1" component="h4">
                            {step.step_name}
                        </Typography>
                        {
                            step.dependency_step_name ?
                                <Typography variant="caption" component="p">
                                    {`${intl.get("TRAIL_STEP_DEPENDENCY_TEXT_1")} ${step.dependency_step_name} ${intl.get("TRAIL_STEP_DEPENDENCY_TEXT_2")}`}
                                </Typography>
                                :
                                step.step_warning ?
                                    <Typography variant="caption" component="p">
                                        {step.step_warning}
                                    </Typography>
                                    : null
                        }
                    </div>
                    {
                        step.available && step.available === "N" ?
                            <Icon ic="lock" className="icon-sm ml-3" />
                            :
                            config.learn.trail.stage.stage_type ?
                                <div className="stage-type">
                                    <Icon ic={typeStepIcon(step.step_type)} color="primary" className="icon-xs" />
                                </div>
                                :
                                <Icon ic="chevron_right" color="lighter" className="icon-xxxs" />
                    }
                </div>
            </div>
        )
    }

    return (
        config.learn.trail.unified_stages.display ?
            <Accordion className="btn-card __subcategory" disabled={!unlock} defaultExpanded={config.learn.trail.unified_stages.expanded === "auto" ? !complete : config.learn.trail.unified_stages.expanded === true ? true : false}>
                <AccordionSummary
                    expandIcon={unlock ? <Icon ic="chevron_down" color="lighter" className="icon-xxxs" /> : <Icon ic="chevron_up" color="lighter" className="icon-xxxs" />}
                    className="subcategory-content __text"
                >
                    <div className="accordion-content">
                        <div className="d-flex align-items-center w-100">
                            {
                                complete ?
                                    <Icon ic="checked" color="success" className="img-xs mr-2" />
                                    : null
                            }
                            <Typography variant="body1" component="h4">
                                {item.title}
                            </Typography>
                        </div>
                        {
                            item.name_stage_dependency ?
                                <Typography variant="caption" component="p">
                                    {`${intl.get("TRAIL_STAGES_DEPENDENCY_TEXT_1")} ${item.name_stage_dependency} ${intl.get("TRAIL_STAGES_DEPENDENCY_TEXT_2")}`}
                                </Typography>
                                :
                                !unlock && item.date_stage_release ?
                                    <Typography variant="caption" component="p">
                                        {`${intl.get("TRAIL_STAGES_DATE_RELEASE_TEXT")} ${getDateTimeTimezone(item.date_stage_release)}`}
                                    </Typography>
                                    :
                                    item.description ?
                                        <Typography variant="caption" component="p">
                                            {item.description}
                                        </Typography>
                                        : null

                        }
                        <div className="subcategory-info">
                            {
                                config.learn.trail.type === "stars" && config.learn.trail.stage.stars &&
                                <div className="subcategory-info-item __stars">
                                    <Icon ic="star_filled" />
                                    <Typography variant="body2" component="span">
                                        {item.current_stars + ' / ' + item.total_stars}
                                    </Typography>
                                </div>
                            }
                            {
                                config.learn.trail.stage.stages &&
                                <div className="subcategory-info-item __stages">
                                    <Icon ic="stages" />
                                    <Typography variant="body2" component="span">
                                        {item.finished_steps + ' / ' + item.total_steps}
                                    </Typography>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        item.has_files === "Y" ?
                            <IconButton aria-label="materials"
                                onClick={() => {
                                    history.push({
                                        pathname: `/trail/${idTrail}/${item.id_stage}/materials`,
                                        state: {
                                            origin: 'details'
                                        }
                                    })
                                }}>
                                <Icon ic="book_stack_filled" />
                            </IconButton>
                            : null
                    }
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        {
                            item.steps.map((step, index) => {
                                return (
                                    <div key={step.id_step + index} className="btn-step">
                                        <StepButton step={step} />
                                        {
                                            item.steps.length > 1 ?
                                                <div className={`step-bar ${step.step_cleared === 'Y' || (index === item.steps.length - 1 && item.steps[item.steps.length - 2].step_cleared === 'Y') ? '__active' : '__disabled'}`}></div>
                                                : null
                                        }
                                    </div>
                                )
                            })
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>
            :
            <Button variant="outlined" className="btn-card __subcategory" disabled={!unlock} onClick={action}>
                <div className="subcategory-content __text">
                    <div className="d-flex align-items-center w-100">
                        {
                            complete ?
                                <Icon ic="checked" color="success" className="img-xs mr-2" />
                                : null
                        }
                        <Typography variant="body1" component="h4">
                            {item.title}
                        </Typography>
                    </div>
                    {
                        item.name_stage_dependency ?
                            <Typography variant="caption" component="p">
                                {`${intl.get("TRAIL_STAGES_DEPENDENCY_TEXT_1")} ${item.name_stage_dependency} ${intl.get("TRAIL_STAGES_DEPENDENCY_TEXT_2")}`}
                            </Typography>
                            :
                            !unlock && item.date_stage_release ?
                                <Typography variant="caption" component="p">
                                    {`${intl.get("TRAIL_STAGES_DATE_RELEASE_TEXT")} ${getDateTimeTimezone(item.date_stage_release)}`}
                                </Typography>
                                :
                                item.description ?
                                    <Typography variant="caption" component="p">
                                        {item.description}
                                    </Typography>
                                    : null

                    }
                    <div className="subcategory-info">
                        {
                            config.learn.trail.type === "stars" && config.learn.trail.stage.stars &&
                            <div className="subcategory-info-item __stars">
                                <Icon ic="star_filled" />
                                <Typography variant="body2" component="span">
                                    {item.current_stars + ' / ' + item.total_stars}
                                </Typography>
                            </div>
                        }
                        {
                            config.learn.trail.stage.stages &&
                            <div className="subcategory-info-item __stages">
                                <Icon ic="stages" />
                                <Typography variant="body2" component="span">
                                    {item.finished_steps + ' / ' + item.total_steps}
                                </Typography>
                            </div>
                        }
                    </div>
                </div>
                {
                    unlock ?
                        <Icon ic="chevron_right" color="lighter" className="icon-xxxs" />
                        :
                        <Icon ic="lock" />
                }
            </Button>
    )
}
